import axios  from 'axios'
import config from '../../env.js';
import auth from '../Authed/Authed.js';
import { errorPopper } from 'services/Helpers/Helpers.js';
import { handleBlobResponse } from '../Helpers/Helpers.js';

const defaultHeaders = {
    'Content-Type':  `application/json;charset=utf-8`,
}


export const apiurl = config.api.url + '/api/v1/';

/**
 * Create an Axios Client with defaults
 */
let client = axios.create({
    baseURL: apiurl,
});

/**
 * Add authentication token as a default. If you want to send a request
 * without a token you can add options.notoken as true
 */
const addHeaders = (options) => {
    let headers = {...defaultHeaders};
    if(options['Content-Type'])
    {
        headers['Content-Type'] = options['Content-Type'];
    }

    // Dont add authentication token when notoken is set
    if(options.notoken) {
        delete options.notoken;
    } else {
        let token = auth.getToken();
        if(token)
        {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }
    options.headers = headers;

    return options;
};

/**
 * When token is out of date, server responds with 500 with
 * data {success: false, error: 'Unauthenticated.'}
 * ... or sometimes with 401 with message Unauthenticated
 *
 **/
const isNotAuthenticated = (response) => {
    return (response.status === 401
        && response.data
        && response.data.message === 'Unauthenticated.'
    ) || (response.status === 500
        && response.data
        && response.data.success === false
        && response.data.error === 'Unauthenticated.'
    );
};

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options) {

    const onSuccess = function(response)
    {
        console.debug('Request Successful!', response);
        if (options.responseType === 'blob' && options.fileTitle) {
            handleBlobResponse(response.data, options.fileTitle)
        }
        return response.data;
    }

    const onError = function(error)
    {
        if (error.response)
        {
            if(isNotAuthenticated(error.response))
            {
                auth.logout( false );
            }

            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:',  error.response.status);
            console.error('Data:',    error.response.data);
            console.error('Headers:', error.response.headers);
        }
        else
        {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        if( options.errorPopper )
            errorPopper(error, options.errorPopper );

        throw error;
        // return Promise.reject(error.response || error.message);
    }

    // Set custom headers for the request
    options = addHeaders(options);

    if(!options.notoken && !options.norefreshing)
    {
        auth.tokenCheckAndRefresh();
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
}

export default request;
