import ApButton from "common/ApButton/ApButton";
import ApFileUpload from "common/ApFileUpload/ApFileUpload";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApModal from "common/ApModal/ApModal";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React, { useState } from "react";
import { errorPopper, getFileById, tr } from "services/Helpers/Helpers";
import api from 'services/Api/Api';
import ApTooltip from "common/ApTooltip/ApTooltip";

const MaterialCertificateEdit = (props) => {
    const [entryData, setEntryData] = useState({
        id: null,
        name: '',
        files: [],
        newFileIds: [],
        removeFileIds: [],
    });
    const [loading, setLoading] = useState(false);

    const onOpen = () => {
        if (props.selectedRow) {
            setEntryData(props.selectedRow);
        }
    }

    const onClose = (newEntry = null) => {
        setEntryData({
            id: null,
            name: '',
            newFileIds: [],
        });
        props.onClose(newEntry);
    }

    const renderHeader = () => {
        return (
            <div className="padding">
                <h4>{tr('material_certificate')}</h4>
            </div>
        );
    }

    const renderBody = () => {
        return (
            <div className='padding'>
                <p>{entryData.name}</p>
                {entryData.files && entryData.files.map(certFile => {
                    if (entryData.removeFileIds && entryData.removeFileIds.includes(certFile.id)) return null;
                    return (
                        <ApInputStack gap="0">
                            <ApTooltip text={tr('download_file')} block>
                                <ApAddon
                                    onClick={() => getFileById(
                                        certFile.file.id,
                                        certFile.file.original_name,
                                        setLoading,
                                    )}
                                    className="pointer"
                                >
                                    {certFile.file.original_name}
                                </ApAddon>
                            </ApTooltip>
                        </ApInputStack>
                    )
                })}
            </div>
        );
    }

    const renderFooter = () => {
        const hasFiles = (entryData.newFileIds && entryData.newFileIds.length > 0)
            || (entryData.files && entryData.files.length > 0);
        return (
            <div className="padding justify-space-between">
                <ApButton onClick={() => onClose()}><SvgIcon type="solid" icon="times" /> {tr('back')}</ApButton>
            </div>
        );
    }

    return (
        <div id="materialCertificateEdit">
            <ApModal
                className="narrow"
                show={props.show}
                onOpen={onOpen}
                onClose={() => onClose()}
                handleClose={() => onClose()}
                header={renderHeader()}
                body={renderBody()}
                footer={renderFooter()}
                closeFromBg
            />
        </div>
    );
}

export default MaterialCertificateEdit;