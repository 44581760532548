import { tr } from 'services/Helpers/Helpers.js';

// Construct navigation bar according to logged-in user's permissions

// Values for a link:
// id:          used to mark a menu as active
// disabled:    disables the menu item, used to display content that has not yet been implemented
// to:          where clicking this will redirect the user
// icon:        icon for the link
// name:        link title
// permissions: array of permision user needs to have in order to see the menu item
//               - empty array ( [] ) means it's available for everybody
//               - permission can also be optional ( use suffix "_?" )
//               - at least one optional is required (if optional permissions available)
//               - Examples: 
//                  [ "first", "second", "third"]           => all three permissions required 
//                  [ "first_?", "second_?", "third_?" ]    => any one of three permission is required 
//                  [ "first", "second_?", "third_?" ]      => "first" is required with either "second" or "third" 
//               
// subMenuOpen: submenu open by default 
// subMenu:     submenu items

const sideNavItems = [

    {
        id: "home",
        to: "/",
        icon: "home",
        name: "Etusivu",
        permissions: []
    },
    {
        id: "timetracking",
        to: "/timetracking",
        icon: "clock",
        name: "Työaikojen hyväksyntä",
        permissions: []
    },
    {
        id: "projects",
        to: "/projects",
        icon: "sitemap",
        name: "Projektit",
        permissions: []
    },
    {
        id: "materialCertificates",
        to: "/materialcertificates",
        icon: "certificate",
        name: tr('material_certificates'),
        permissions: []
    },
    {
        id: "assignmentRequests",
        to: '/assignments',
        icon: "tasks",
        name: tr('assignment_requests'),
        permissions: [],
    },
    {
        id: "documents",
        to: "/documents",
        icon: "folder-open",
        name: "Dokumentit",
        permissions: []
    },
    {
        id: "promos",
        to: "/promos",
        icon: "file-alt",
        name: "Esitteet",
        permissions: []
    },
    { 
        id: "reports",
        to: "/reports",
        icon: "chart-pie",
        name: "Raportit",
        permissions: [],
    },
    {
        id: "permits",
        to: '/permits',
        icon: "id-card",
        name: "Luvat",
        permissions: [],
    },
    {
        id: "customerPortal",
        to: '/supplierportal/components',
        icon: "box",
        name: "Toimittaja portaali",
        permissions: [],
    },

];



export default sideNavItems;
