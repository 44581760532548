/* eslint-disable eqeqeq */
import React from 'react';
import moment from 'moment';
import Highlighter from "react-highlight-words";

import auth         from 'services/Authed/Authed.js';
import api, { apiurl }   from 'services/Api/Api.js';


export function parseNumber( number ) {
    let value = parseFloat( number );
    if( !isNaN( value ) ) 
        return value;
    return 0;
}


export function roundToDecimals(value, decimals)
{
    const preciseRound = (num, decimals) => {
        const tmp = Math.pow(10, decimals);
        return (Math.round((num * tmp) +
            (decimals>0?1:0)*(Math.sign(num) *
            (10 / Math.pow(100, decimals)))) / tmp
            ).toFixed(decimals);
    }
    return parseFloat(preciseRound(parseFloat(value), decimals)).toFixed(decimals);
}

export function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = " ") 
{
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount),10).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        if( isNaN( i )) return ""
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function validateEmail(email) {

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateDate( dateString, emptyIsValid = false ) {
    if( emptyIsValid && !dateString )
        return true;
    return moment( dateString, 'YYYY-MM-DD', true ).isValid() ? true : moment( dateString, 'DD.MM.YYYY', true ).isValid();
}

export function isObject(v)
{
    return (v !== null && typeof v === 'object')
}

export function JWTParser(token)
{
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export function onlyNumber(value, negative = false)
{
    if( typeof value === 'number' ) value = String( value );

    // Replace , with .
    value = value.replace(',', '.');
    // only allow one separator
    let first = true;
    value = value.replace(/\./g, (match) => {
        if(first)
        {
            first = false;
            return match;
        }
        return '';
    });

    // only allow numbers and separator
    let regExp = /[^0-9\.]/g;

    // Allow negative marking as first character if negative is true
    if(negative)
    {
        regExp = /[^0-9\.-]/g;
        value = value.replace(/-/g, (match, offset) => {
            if(offset === 0)
                return match;
            return '';
        });
    }

    value = value.replace(regExp, '');
    return value;
}

export function decimalCount(num)
{
  var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) { return 0; }
  return Math.max(
       0,
       // Number of digits right of decimal point.
       (match[1] ? match[1].length : 0)
       // Adjust for scientific notation.
       - (match[2] ? +match[2] : 0));
}

export function ucfirst(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncate( text, maxLength ) 
{
    if( text.length > maxLength )
        text = text.substr(0, maxLength - 1 ) + "…";

    return text;
}

export function pluralizeCount( count, single, plural )
{
    if( typeof( count ) == "object" && typeof( count.length ) == "number" )
        count = count.length;

    return ( count ? count : 0 ) + " " + ( count == 1 ? single : plural );
}

export function getLastCodePart( code, delimiter = "." )
{
    if( !code )
        return false;
    
    const parts = code.split( delimiter );
    return parts[ parts.length - 1 ];
}

export function readableFileSize(size) {
    if( !size )
        return "";

    var units = ['t', 'kt', 'Mt', 'Gt', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;
    while(size >= 1024) {
        size /= 1024;
        ++i;
    }
    return size.toFixed(1) + ' ' + units[i];
}

export function reactTableTranslate()
{
    return {
        previousText: 'Edellinen',
        nextText: 'Seuraava',
        loadingText: 'Lataa...',
        noDataText: 'Rivejä ei löytynyt',
        pageText: 'Sivu',
        ofText: '/',
        rowsText: 'riviä',
    }
}


// Add parent ids to all items in nested object
// Params:
//  - obj:          array of data to loop through
//  - child:        child elements name (nested items)
//  - parentField:  name of the field parents are saved to (defaults to "parents")
// Returns: object (with matching id) or false (no matching id found)
export function addParentIds( obj, child, parentField )
{
    if( typeof( parentField ) != "string" ) parentField = "parents";
    const loop = function( data, parents )
    {
        if( typeof( parents ) != "object" ) parents = [];
        for( let i = 0; i < data.length; i++ )
        {
            data[i][ parentField ] = parents;
            if( typeof( data[i][ child ] ) == "object" )
            {
                data[i][ child ] = loop( data[i][ child ], parents.concat( data[i].id ) );
            }
        }
        return data;
    }
    return loop( obj );
}




// Find and return single item from (nested) array of objects by id
// Params:
//  - obj:      array of data to loop through
//  - field:    field name to look for 
//  - value:       value to look for the field
//  - child:    possible child elements name (nested search)
// Returns: object (with matching value) or false (no matching value found)
export function findItemByField( obj, field, value, child = "children" )
{
    if( typeof( obj ) == "object" && obj.length )
    {
        const loop = function( data )
        {
            let result = false;
            for( let i = 0; i < data.length; i++ )
            {
                if( data[i][ field ] == value )
                {
                    result = data[i];
                    break;
                }
                // Check for nested elements
                else if ( typeof( data[i][ child ] ) == "object" )
                {
                    result = loop( data[i][ child ] );
                    if( result ) break;
                }
            }
            return result;
        }
        return loop( obj );
    }
    return false;
}

// Find and return single item from (nested) array of objects by id
// Params:
//  - obj:      array of data to loop through
//  - id:       id to look for
//  - child:    possible child elements name (nested search)
// Returns: object (with matching id) or false (no matching id found)
export function findItemById( obj, id, child = "children" )
{
    return findItemByField( obj, 'id', id , child );
}

// Loop through array of data and edit item(s) that matches the id
// Params:
//  - obj:      array of data to loop through
//  - id:       id to look for (if id starts with "!" look all but that id)
//  - edits:    object of modifications. NOTE: You can toggle boolean value by givin it value of "!";
//  - child:    possible child elements name (nested search)
// Returns: edited object or false (if object not provided)
export function editItemById( obj, id, edits, child )
{
    id = String(id);

    // If id's first char is "!"
    const exludeMode = (id.charAt(0) == "!" ? true : false );
    if( exludeMode )
    {
        id = id.substr(1);
    }

    if( typeof( obj ) == "object" && obj.length )
    {
        const loop = function( data )
        {
            for( let i = 0; i < data.length; i++ )
            {
                // Look for matcing id or if in exludeMode look anything else but matching id
                if( ( !exludeMode && data[i].id == id ) ||
                    ( exludeMode && data[i].id != id ) )
                {
                    for(let prop in edits)
                    {
                        // SPECIAL CASE: toggle boolean value with "!"
                        if( edits[ prop ] === "!" && typeof( data[i][ prop ] ) == "boolean" )
                            data[i][ prop ] = !data[i][ prop ];
                        else
                            data[i][ prop ] = edits[ prop ];
                    }
                }
                // Check for nested elements
                if ( typeof( data[i][ child ] ) == "object" )
                {
                    data[i][ child ] = loop( data[i][ child ] );
                }
            }
            return data;
        }
        return loop( obj );
    }
    return false;
}

// Map the tree structure
// Params:
//  - data:      array of data to loop through
//  - func:      function that will be ran on every tree node, should return data for tree node
//  - funcAfter: function that will be ran on every tree node after children, should return data for tree node
//  - child:     possible child elements name (nested search)
// Returns: array that map returns
export function mapTree(data, func = null, funcAfter = null, child = 'children', parent = null, parentIndexArray = [])
{
    if(Array.isArray(data) && data.length > 0)
    {
        return data.map( ( item, key ) => {
            let indexArray = [ ...parentIndexArray, key ];
            if (typeof func === 'function')
                item = func( item, parent, indexArray );

            if( typeof item === 'undefined' )
                console.error('mapTree function probably missing return')

            if(Array.isArray(item[child]) && item[child].length > 0)
                item[child] = mapTree(item[child], func, funcAfter, child, item, indexArray );

            if (typeof funcAfter === 'function')
                item = funcAfter( item, parent, indexArray );

            if( typeof item === 'undefined' )
                console.error('mapTree function probably missing return')

            return item;
        })
    }
    return data;
}

// Loop the tree structure
// Params:
//  - data:     array of data to loop through
//  - func:     function that will be ran on every tree node
//  - child:    possible child elements name (nested search)
export function iterateTree(data, func, child = 'children', parent = null, parentIndexArray = [] )
{
    if(Array.isArray(data) && data.length > 0)
    {
        data.forEach( ( item, key ) => {
            let indexArray = [ ...parentIndexArray, key ];
            func( item, parent, indexArray );
            if(Array.isArray( item[child]) && item[child].length > 0 )
                iterateTree( item[child], func, child , item, indexArray );
        })
    }
}

// Map the tree structure and update node selected by id and its children
// Params:
//  - data:      array of data to loop through
//  - id:        id value of the node to update
//  - func:      function that will be ran on every tree node, should return data for tree node
//  - funcAfter: function that will be ran on every tree node after children, should return data for tree node
//  - child:     possible child elements name (nested search)
// Returns: array that map returns
export function mapChildrenOfId(data, id, func = null, funcAfter = null, child = 'children', parent = null, parentIndexArray = [])
{
    if(Array.isArray(data) && data.length > 0)
    {
        return data.map( ( item, key ) => {
            let indexArray = [ ...parentIndexArray, key ];

            // Littlebit hacked method where id passed to children is false
            let doUpdate = false;
            if( id === false ) doUpdate = true;
            if( item.id === id ) doUpdate = true;

            if ( doUpdate && typeof func === 'function' )
                item = func( item, parent, indexArray );

            if(Array.isArray(item[child]) && item[child].length > 0)
                item[child] = mapChildrenOfId(item[child], doUpdate ? false : id, func, funcAfter, child, item, indexArray );

            if ( doUpdate && typeof funcAfter === 'function' )
                item = funcAfter( item, parent, indexArray );

            return item;
        })
    }
    return data;
}


// Sort object by given field
// Params:
//  - data:   object t soft
//  - field:  field to sort by
export function sortByField(data, field)
{
    return data.sort((a, b) => {
        const fieldA = a[field].toUpperCase();
        const fieldB = b[field].toUpperCase();
        if (fieldA < fieldB) {
            return -1;
        }
        if (fieldA > fieldB) {
            return 1;
        }
        return 0;
    });
};



// Convert DB date time to human readable format
// Params:
//  - value:  String value of datetime (YYYY-MM-DD HH:MM:SS)
export function timeSince(value)
{
    const time = moment( value );
    // const seconds = moment().diff( time, "seconds" );
    const minutes = moment().diff( time, "minutes" );
    const hours = moment().diff( time, "hours" );
    const days = moment().diff( time, "days" );
    const months = moment().diff( time, "months" );
    const years = moment().diff( time, "years" );

    if( years > 1 )         return years + ` ${tr('years_ago')}`;
    else if( years == 1 )   return `${tr('one_year_ago')}`;
    else if( months > 0 )   return months + ` ${tr('months_ago')}`;
    else if( days > 2 )     return days + ` ${tr('days_ago')}`;
    else if( days == 2 )    return `${tr('day_before_yesterday')}`;
    else if( days == 1 )    return `${tr('yesterday')}`;
    else if( hours > 0 )    return hours + ` ${tr('hours_ago')}`;
    else if( minutes > 0 )  return minutes + ` ${tr('minutes_ago')}`;
    else                    return `${tr('moment_ago')}`;
};

/*
// Add hilight to string also converts string to xml element
// Params:
//   - find:  Needle
//   - text:  Haystack
//   - style: for highligted text
export function highlight( find, text, style )
{
    if( typeof text !== 'string' )
        return text

    find = find.toLowerCase();
    let loweredText = text.toLowerCase();

    const sPos = loweredText.indexOf(find);

    if(find === '')
        return text;

    if(sPos !== -1)
    {
        const ePos = sPos + find.length;
        text = <span>
            {text.slice( 0, sPos )}
            <span style={ style }>
                {text.slice( sPos, ePos )}
            </span>
            {text.slice( ePos )}
        </span>;
    }
    return text;
}
*/

export function highlight( find, text, style = {} )
{
    if( !find || typeof( find ) != "string" )
        return text;

    return <Highlighter
        highlightClassName="apHighlight"
        searchWords={ [ find ] }
        autoEscape={ true }
        highlightStyle={ style }
        textToHighlight={ text }
    />
}

// Javascript math operation cause strange rounding errors
// this will remove those (example: 1 - 0.1 = 0.90000001)
// Params:
//   - value: value
//   - decimal: how many max decimals to keep
export function removeRoundOff( value, decimal = 4 )
{
    decimal = 10 ** decimal;
    value = Math.round( value * decimal )
    value = value / decimal;
    return value;
}

// Helper to popup and display laravel abort messages
// Params:
//   - error: error from api
//   - text: default text
export function errorPopper(error, text = 'Tapahtui virhe' )
{
    if( typeof error !== 'undefined' )
    {
        if( keyExists( error, 'response.data.message' ) && error.response.data.message )
            text = error.response.data.message;
    }
    window.emitter.emit('popper', {
        type: 'danger',
        content: <strong>{text}</strong>,
    });
    return text;
}

export function dateInputToSql( value, includeTime = false )
{
    return value ? moment( value, 'DD.MM.YYYY' + ( includeTime ? " HH.mm" : "" ) ).format( 'YYYY-MM-DD' + ( includeTime ? " HH:mm:ss" : "" ) ) : null;
}

export function sqlToDateInput( value, includeTime = false )
{
    return value ? moment( value ).format( 'DD.MM.YYYY' + ( includeTime ? " HH.mm" : "" ) ) : null;
}

export function getImageUrl( file_id )
{
    return apiurl + "file/id/" + file_id + "/logo?token=" + auth.getToken();
}


// Helper to check if key exists in nested object (and return its value)
// Params:
//   - obj: root object
//   - path: path as a string
//   - returnValue: boolean, TRUE = returns value of the object if exists
//
// Example:  const nameAvailable = keyExists( this.state.data, 'parent.child.grandschild.item.name' );
//           const maxSize = keyExists( settings, 'files.max-size', true );
//
export function keyExists( obj, path, returnValue = false, errorValue = false )
{
    const levels = path.split(".");
    for (var i = 0; i < levels.length; i++) {
        if (!obj || !obj.hasOwnProperty( levels[i] ) )
            return ( returnValue && errorValue ? errorValue : undefined );
        obj = obj[ levels[i] ];

        if( returnValue && i == levels.length - 1 )
            return obj;
    } 
    return true;
}

// Helper to set nested object value by given dot notation
// Params:
//   - obj: root object
//   - path: path as a string
//   - value: new value for object
//
// Example:
//  let obj = { 
//      a: { 
//          b: {
//              c: 1
//          },
//          items: [
//              { name: "first" },
//              { name: "second" },
//              { name: "?" }
//          ]
//      }
//  };
//
//  obj = setNested( obj, "a.b.c", 2 );
//  obj = setNested( obj, "a.items[2].name", "third");
//
export function setNested( obj, path, value )
{
    const pList = path.split('.');
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
   
        // Have array index notation [x] at the end
        const array = currentValue.match( /\[[0-9]+\]$/ );
        if( array )
        {
            const arrayKey = currentValue.substring( 0, array.index );
            const index = parseInt( array[0].replace( /\D/g, '' ) );
            
            if( accumulator[ arrayKey ] === undefined )
                accumulator[ arrayKey ] = [];

            if( accumulator[ arrayKey ][ index ] === undefined )
                accumulator[ arrayKey ][ index ] = {};

            return accumulator[ arrayKey ][ index ];
        }
        
        if (accumulator[currentValue] === undefined) 
            accumulator[currentValue] = {};
        return accumulator[currentValue];

    }, obj);
    pointer[key] = value;
    return obj;
}

// Creates grouped object from list of items (with )
// Params:
//   - items:       array of objects
//   - groupKey:    key to group identifier
//   - resultFunc:  optional function to parse group content
//
/* Example:
 
    const list = [
        { name: "A", category: "foo" }, 
        { name: "B", category: "foo" },
        { name: "C", category: "bar" },
        { name: "D", category: "foo" },
        { name: "E", category: "bar" },
        { name: "F" }
    ];

    const result = groupify( list, "category" );
    result = { 
        foo: [ 
            { name: "A", category: "foo" }, 
            { name: "B", category: "foo" },
            { name: "D", category: "foo" }
        ], 
        bar: [
            { name: "C", category: "bar" },
            { name: "E", category: "bar" }
        ],
        undefined: [
            { name: "F" }
        ]
    }
*/
export function groupify( items, groupKey, resultFunc = false )
{
    // By default return whole item 
    if( typeof( resultFunc ) != "function" )
        resultFunc = ( item ) => { return item };  

    let groups = {};
    let noGroup = [];
    items.forEach( item => {
        const groupId = item[ groupKey ] ? item[ groupKey ] : false;
        if( groupId )
        {
            if( !( groupId in groups ) )
                groups[ groupId ] = [];

            groups[ groupId ].push( resultFunc( item ) );
        }
        else 
        {
            noGroup.push( resultFunc( item ) );
        }
    });

    if( noGroup.length > 0 )
        groups[ 'undefined' ] = noGroup;

    return groups;
}

export function randomPassword( length = 8 )
{
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let pass = "";
    for( let i = 0; i < length; i++ ) {
        pass += charset.charAt( Math.floor ( Math.random() * charset.length ) );
    }
    return pass;
}

export function profitPercentToSellPrice( price, percent )
{
    return ( price / ( 1 - ( percent / 100 ) ) );
}

export function priceToProfitPercent( price, priceSell )
{
    return ( ( (price - priceSell ) / priceSell ) * -1 * 100 );
}

export function profitPercentToPrice( priceSell, percent)
{
    return (  -1 * percent / 100 * priceSell + priceSell )
}



export function objectIsEmpty( obj ) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export function pick(o, ...props) {
    return Object.assign({}, ...props.map(prop => ({[prop]: o[prop]})));
}

export function capitalize( value ) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

// Takes array of permissions and checks that all of them return true
// when checked using auth user can
export function hasPermissions( ps )
{
    if( typeof ps === 'string') ps = [ ps ];
    return ps.every( p => auth.hasModule( p ) );
}

export function formatPhoneNumber(number)
{
    /**
     * Not in use. Was started what realised that there is no use to make another setting for clients just to format phonenumbers to their liking
     * Left here if someone want to continue
     */
    if (number.length <= 0) return number
    if (typeof(number) !== 'string') return number

    let n = number.replace(/\s/g,'')

    const countryCodeThree = () => {
        return n.substring(0, 4) + ' ' + n.substring(4, 6) + ' ' + n.substring(6, 10) + ' ' + n.substring(10, 18)
    }

    const formatFinMobile = () => {
        return n.substring(0, 3) + '-' + n.substring(3, 7) + ' ' + n.substring(7, 17)
    }
    
    if (n.charAt(0) === '+') {
        if (n.substring(1, 4) === '358') return countryCodeThree() //finland with +358
        //TODO other country codes    
    }

    const fin = ['044', '041', '042', '043', '044', '045', '046', '049', '050']

    if (n.length === 10 && fin.includes(n.substr(0, 3))) return formatFinMobile()


    return number
}

export function tr(key, variables = [])
{
    let translation = window.translations ? key.split('.').reduce((t, i) => t[i] || '', window.translations) : ''
    if (translation.length > 0 && variables.length > 0) 
    {
        let trArray = translation.split(' ')
        let count = 0
        if (variables.some(value => typeof value === 'object'))
        {
            translation = trArray.map((item, index) => {
                if (item.startsWith('$'))
                {
                    item = variables[count]
                    count++
                }
                return typeof item === 'object' ? item : (trArray[index-1] && trArray[index-1].startsWith('$') && typeof variables[count-1] === 'object' ? ' ' + item + ' ' : item + ' ')
            })
        }
        else
        {
            translation = ''
            for (let i = 0; i < trArray.length; i++)
            {
                if (trArray[i].startsWith('$'))
                {
                    const chars = '!"#%&/\()=?,.'
                    const lastChar = trArray[i].slice(-1)
                    trArray[i] = chars.includes(lastChar) ? variables[count] + lastChar : variables[count]
                    count++
                }
                translation += trArray[i];
                if (i != trArray.length - 1) {
                    translation += ' ';
                }
            }
        }
    }
    return translation
}

export function currentLang()
{
    moment.locale('fi');
    let value = window.document.cookie.split('; ').find(row => row.startsWith('language'));
    return value ? value.split('=')[1] : 'fi';
}

export function availableLanguages()
{
    return [
        { code: "fi", flag: "/img/flags/fi.png" },
        { code: "en", flag: "/img/flags/en.png" }
    ];
}

export function translateLangCode(code)
{
    const langName = new Intl.DisplayNames([currentLang()], {type: 'language'})
    return langName.of(code)
}

export function handleBlobResponse(response, fileTitle = "EASY-PRO excel") {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileTitle);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * Retrieves a file by its ID.
 *
 * @param {string} fileId - The ID of the file to retrieve.
 * @param {string} fileName - The name of the file.
 * @param {function} [setLoading=null] - Optional function to set the loading state.
 * @param {function} [onSuccess=null] - Optional callback function to be called on successful retrieval.
 * @param {function} [onError=null] - Optional callback function to be called on error.
 */
export function getFileById(fileId, fileName, setLoading = null, onSuccess = null, onError = null) {
    if (setLoading && typeof setLoading === 'function') setLoading(true);
    api({
        method: 'get',
        responseType: 'blob',
        url: 'extranet/file/id/' + fileId,
        fileTitle: fileName,
    }).then((response) => {
        if (onSuccess && typeof onSuccess === 'function') onSuccess(response);
    }).catch((error) => {
        if (onError && typeof onError === 'function') onError(error);
        else errorPopper(error, tr('get_error'));
    }).finally(() => {
        if (setLoading && typeof setLoading === 'function') setLoading(false);
    });
}