import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import { Link } from 'react-router-dom';
import { pluralizeCount } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import './Home.css';
import moment from 'moment';

export default class Home extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {

			stats: [],
			titleText: "",
			newsitems: [],
			showStats: false,
			showNews: false,
			showTimetracking: false,
			showProjects: false,
			showPermits:false,
			approvableEntries: 0,
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{
	
		this.getSettings();
		this.getNewsitems();
		this.getApprovableEntries();
	}

	componentDidMount()
	{

		const stats = [
			// {
			// 	title: "Tuntikirjaukset",
			// 	value: "0",
			// 	info: "Ei hyväksymättömiä kirjauksia"
			// },
			// {
			// 	title: "Päivät ilman työtapaturmia",
			// 	value: "1032",
			// 	info: "Ennätys 2851 päivää"
			// },
			// {
			// 	title: "Käynnissä olevat projektit",
			// 	value: "2",
			// 	info: ""
			// },
			// {
			// 	title: "Uusia viestejä",
			// 	value: "4",
			// 	info: ""
			// }
		];

		this.setState({ stats });

	}

	getApprovableEntries = () => {
		api({
			method: 'get',
			url: `extranet/client/approvable`
		}).then((response) => {
			this.setState({
				approvableEntries: response
			});
		}).catch((error) => {
			console.log(error);
		});
	};

	getSettings()
	{

		api({
			method: 'get',
			url: 'extranet/client/settings'
		}).then((response) => {
			// console.log(response);
			this.setState({ 
				titleText: response.title_text,
				showStats: response.show_statistics,
				showTimetracking: response.show_timetracking,
				showProjects: response.show_projects,
				showNews: response.show_news,
				showPermits:response.show_permits,
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	getNewsitems()
	{

		api({
			method: 'get',
			url: 'extranet/client/getNewsitems'
		}).then((response) => {
			this.setState({ newsitems: response });
		}).catch((error) => {
			console.log(error);
		});

	}

	clickProject()
	{

		console.log("goto project");

	}

	render()
	{

		return(
			
			<div id="Home">

				<div className="apBox">

					<div className="apBoxHeader">
						<h1> Extranet verkkopalvelu </h1>
						<div style={{padding: '8px'}}>
							{this.state.titleText}
						</div>							
					</div>

					<div className="padding">

						{this.state.showStats &&
						<div className="stats">
							{this.state.stats.map((stat, index) => {
								return (
									<div className="stat" key={index}>
										<SvgIcon icon="archive" type="solid" />
										<div className="title"> {stat.title} </div>
										<div className="value"> {stat.value} </div>
										<div className="info"> {stat.info} </div>
									</div>
								);
							})}
						</div>
						}

						{this.state.showNews &&
							<div className="apBox block">
								<div className="apBoxHeader">
									<h3>
										<SvgIcon icon="newspaper" type="solid" />
										Uutiset
									</h3>
								</div>
								{this.state.newsitems.map((item, index) => {
									return(
										<div key={index}>
											<div className="blockContent">
												<div style={{paddingLeft: '16px', paddingTop: '8px', fontSize: '0.8em'}}>
													<h3>
														<span> {moment(item.created_at).format('L')} </span>
														<span style={{paddingLeft: '8px'}}> {item.header} </span>
													</h3>
												</div>
												<div className="list">
													{item.content}
												</div>
											</div>
										{this.state.newsitems[index+1] !== undefined && <hr/> }
										</div>
									);
								})}

							</div>
						}

						{this.state.showTimetracking &&
							<div className="apBox block">
								<div className="apBoxHeader">
									<h3>
										<SvgIcon className="small-inline" icon="clock" type="solid" />
										Tuntikirjausten hyväksyntä
									</h3>
									{ this.state.approvableEntries > 0 ? (
											<Link to="/timetracking" className="linkBox">
													<SvgIcon icon="calendar-check" type="solid" />
													<span>Hyväksyntää odottaa <strong>{ pluralizeCount( this.state.approvableEntries, "kirjaus", "kirjausta" ) }</strong></span>
													<SvgIcon className="arrow" icon="arrow-right" type="solid" />
											</Link> 
									) : (
										<div className="apMsg">
											Ei uusia kirjauksia hyväksyttävänä
										</div>
									)}
								</div>
							</div>
						}

						{/* {this.state.showProjects && 
							<div className="apBox block">
								<div className="apBoxHeader">
									<h3>
										<SvgIcon className="small-inline" icon="sitemap" type="solid" />
										Käynnissä olevat projektit
									</h3>
								</div>
								<div className="list">
									<span style={{paddingRight: '8px'}}> 30002 EASY-PRO </span>
									<SvgIcon 
										className="small-inline" 
										icon="arrow-right" 
										type="solid" 
										onClick={() => this.clickProject()}
									/>
								</div>
							</div>
						} */}

					</div>

				</div>

			</div>

		);

	}

}