
import Home 		from 'modules/Home/Home.js';
import Timetracking from 'modules/Timetracking/Timetracking.js';
import Projects 	from 'modules/Projects/Projects.js';
import Documents 	from 'modules/Documents/Documents.js';
import Promos		from 'modules/Promos/Promos.js';
import UserPage		from 'modules/UserPage/UserPage.js';
import Reports 		from 'modules/Reports/Reports.js';
import Permits 		from 'modules/Permits/Permits.js';
import Components 		from 'modules/CustomerPortal/Components.js';
import Assignments 	from 'modules/Assignments/Assignments.js';
import AssignmentPage from 'modules/Assignments/AssignmentPage/AssignmentPage.js';
import NewAssignmentRequest from 'modules/Assignments/AssignmentPage/NewAssignmentRequest.js';
import MaterialCertificates from '../modules/MaterialCertificates/MaterialCertificates';


const routes = [
	{
		path: '/',
		component: Home,
		menu: 'home'
	},
	{
		path: '/timetracking',
		component: Timetracking,
		menu: 'timetracing'
	},
	{
		path: '/projects',
		component: Projects,
		menu: 'projects'
	},
	{
		path: '/documents',
		component: Documents,
		menu: 'documents'
	},
	{
		path: '/promos',
		component: Promos,
		menu: 'promos'
	},
	{
		path: '/user',
		component: UserPage
	},
	{
		path: '/reports',
		component: Reports,
		menu: 'reports'
	},
	{
		path: '/permits',
		component: Permits,
		menu: 'permits'
	}, {
		path: '/supplierportal/components/:tab?',
		component: Components,
		menu: 'supplierportal'
	},
	{
		path: '/assignments',
		component: Assignments,
		menu: 'assignmentRequests'
	},
	{
		path: '/assignments/:id?',
		component: AssignmentPage,
	},
	{
		path: '/assignment-requests/new/assignment',
		component: NewAssignmentRequest,
	},
	{
		path: '/assignment-requests/:id?',
		component: NewAssignmentRequest,
	},
	{
		path: '/materialcertificates',
		component: MaterialCertificates,
	},

];

export default routes;
